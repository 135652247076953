<template>
  <div class="box box_column main">
    <iframe id="container" frameborder="no" style="width:100%;height:100%;" :src="src"></iframe>
    <!-- <el-button @click="txtbtn">打开</el-button>
    <input
      type="file"
      @change="loadTextFromFile"
      id="txt"
      style="display: none"
    />
    <div id="parentdiv" class="flex1">
      <canvas id="container"></canvas>
    </div> -->
  </div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
export default {
  name: "damageIdentify",
  data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
      meshes: [],
      wireframes: [],
      indices: [],
      vertices: [],
      controls: null,
      src: "",
      colors: [0x888888, 0xef5350, 0xec407a, 0xab47bc, 0x7e57c2, 0x5c6bc0, 0x42a5f5, 0x29b6f6, 0x26c6da, 0x26a69a, 0x66bb6a, 0x9ccc65, 0xd4e157, 0xffee58, 0xffca28, 0xffa726, 0xff7043, 0x8d6e63]
    };
  },
  mounted() {
    //  this.src = './static/gxjk-demage/BCM.v.1.2_cm.html';
    this.src = "http://webtest.cloudansys.com/bridge/static/gxjk-demage/BCM.v.1.2_cm.html";

    document.querySelector("#container").width = document.querySelector("#parentdiv").clientWidth - 10; //窗口宽度
    document.querySelector("#container").height = document.querySelector("#parentdiv").clientHeight - 10; //窗口宽度

    let that = this;
    window.onresize = function() {
      // 定义窗口大小变更通知事件
      let newWidth = document.querySelector("#parentdiv").clientWidth - 10; //窗口宽度
      let newHeight = document.querySelector("#parentdiv").clientHeight - 10; //窗口宽度

      that.renderer.setSize(newWidth, newHeight);
      that.camera.aspect = newWidth / newHeight;
      that.camera.updateProjectionMatrix();
      console.log(that.camera.aspect);
    };
  },
  methods: {
    init: function() {
      this.scene = new THREE.Scene();
      const canvas = document.querySelector("#container");

      this.drawmesh();
      this.camera = new THREE.PerspectiveCamera(50, canvas.clientWidth / canvas.clientHeight, 0.1, 1000);
      this.camera.position.z = 50;
      const light = new THREE.DirectionalLight(0xffffff, 0.5);
      this.scene.add(light);
      this.renderer = new THREE.WebGLRenderer({
        canvas,
        antialias: true,
        alpha: true
      });
      this.renderer.setSize(canvas.clientWidth, canvas.clientHeight);
      console.log(canvas.clientWidth, canvas.clientHeight);
      this.renderer.render(this.scene, this.camera);
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true;
      //动态阻尼系数 就是鼠标拖拽旋转灵敏度
      this.controls.dampingFactor = 0.25;
      //是否可以缩放
      this.controls.enableZoom = true;
      //是否自动旋转
      this.controls.autoRotate = false;
      //设置相机距离原点的最远距离
      this.controls.minDistance = 10;
      //设置相机距离原点的最远距离
      this.controls.maxDistance = 600;
      //是否开启右键拖拽
      this.controls.enablePan = true;
      this.animate();
    },
    animate: function() {
      this.controls.update();
      this.renderer.render(this.scene, this.camera);

      //更新性能插件
      requestAnimationFrame(this.animate);
    },

    txtbtn() {
      document.getElementById("txt").click();
    },
    loadTextFromFile(e) {
      const file = e.target.files[0];
      let name = file.name
        .split(".")
        .splice(-1)
        .toString();
      if (name !== "msh") {
        this.$message.success("文件类型错误,请重新选择文件");
        return;
      }
      const reader = new FileReader();
      if (typeof FileReader === "undefined") {
        alert("您的浏览器不支持FileReader接口");
      }
      reader.onload = (e) => this.$emit("load", this.analyzeData(e.target.result));
      reader.readAsText(file, "utf-8");
    },
    analyzeData(item) {
      let strs = item.split("\r\n");
      let coordinates = strs.filter((item) => item.indexOf("Coordinates") != -1);
      let coordinates_start = strs.indexOf(coordinates[0]);
      let coordinates_end = strs.indexOf(coordinates[1]);
      let elements = strs.filter((item) => item.indexOf("Elements") != -1);
      let elements_start = strs.indexOf(elements[0]);
      let elements_end = strs.indexOf(elements[1]);
      for (let index = coordinates_start + 1; index < coordinates_end; index++) {
        let co_data = strs[index]
          .trim()
          .replace(/\s+/gi, " ")
          .split(" ");
        this.vertices.push(co_data[1], co_data[2], co_data[3]);
      }
      for (let index = elements_start + 1; index < elements_end; index++) {
        let el_data = strs[index]
          .trim()
          .replace(/\s+/gi, " ")
          .split(" ");
        var mat_id = parseInt(el_data[5]);
        while (mat_id > this.indices.length) this.indices.push(new Array());
        this.indices[mat_id - 1].push(el_data[1] - 1, el_data[2] - 1, el_data[3] - 1);
        this.indices[mat_id - 1].push(el_data[1] - 1, el_data[3] - 1, el_data[4] - 1);
      }
      console.log(this.indices);
      this.init();
    },
    drawmesh() {
      for (let index = 0; index < this.indices.length; index++) {
        const element = this.indices[index];
        let geometry = new THREE.BufferGeometry();
        geometry.setIndex(element);
        geometry.setAttribute("position", new THREE.Float32BufferAttribute(this.vertices, 3));
        let material = new THREE.MeshBasicMaterial({
          color: this.colors[index],
          side: THREE.DoubleSide
        });
        let mesh = new THREE.Mesh(geometry, material);
        mesh.renderOrder = index;
        mesh.onBeforeRender = function(renderer) {
          renderer.clearDepth();
        };
        let wireframeMaterial = new THREE.MeshBasicMaterial({
          color: 0x111111,
          wireframe: true,
          //depthTest:false,
          side: THREE.DoubleSide
        });
        //pn是一个mesh
        let wireframe = new THREE.Mesh(geometry, wireframeMaterial);
        wireframe.renderOrder = index;
        wireframe.onBeforeRender = function(renderer) {
          renderer.clearDepth();
        };
        this.meshes.push(mesh);
        this.wireframes.push(wireframe);
      }
      console.log(this.meshes);
      console.log(this.wireframes);
      const group = new THREE.Group();
      this.meshes.forEach((element) => {
        group.add(element);
      });
      this.wireframes.forEach((element) => {
        group.add(element);
      });
      group.position.set(-25, 0, 0);
      this.scene.add(group);
    }
  }
};
</script>

<style scoped lang="scss">
.main {
  height: 100%;
}
#container {
  width: 100%;
  height: 100%;
}
</style>
